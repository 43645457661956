<template>
  <div>
    <ui-toolbar :actions="toolbar"></ui-toolbar>
    <ui-cards>
      <ui-card v-for="(location, index) in $store.state.locations" :key="index">
        <template v-slot:head>{{location.name}}</template>
        {{location.description}}
        <hr class="my-2">
        Adresse: <br>
        {{ getAddress(location.addressId).address }}

        <template v-slot:foot>
          <div class="flex flex-row">
            <a class="cursor-pointer mr-3" @click="() => $refs.editLocation.open(location.id)"><i class="fal fa-edit"></i></a>
            <a class="cursor-pointer" @click="() => openDeleteDialog(location.id)"><i class="fal fa-trash"></i></a>
          </div>
        </template>
      </ui-card>
    </ui-cards>
  </div>
  <ui-dialog ref="cannotDeleteDialog" :is-info="true">
    <div class="text-center pb-2">
      Diese Location kann nicht gelöscht werden, da sie in einer Session verwendet wird.
    </div>
  </ui-dialog>
  <ui-dialog ref="deleteDialog" :is-dangerous="true" confirm-label="Löschen" @confirm="doDelete">
    <div class="text-center pb-2">
      Wollen Sie diese Location wirklich löschen?<br><br>
      Dieser Vorgang kann nicht rückgängig gemacht werden.
    </div>
  </ui-dialog>
  <new-location ref="newLocation" />
  <edit-location ref="editLocation" />
</template>

<script>
import NewLocation from "@/components/Admin/dialogs/NewLocation";
import EditLocation from "@/components/Admin/dialogs/EditLocation";

export default {
  name: "LocationsView",
  components: {
    NewLocation,
    EditLocation
  },
  data(){
    return {
      deleteDialogId: -1,
      event: {},
      toolbar: [
        {
          title: 'Neue Location',
          label: `<i class="fa fa-plus"></i>`,
          component: 'button',
          type: 'success',
          action: () => this.$refs.newLocation.open()
        }
      ]
    }
  }, created() {
    this.$store.getLocations()
  },
  methods: {
    openDeleteDialog(id) {
      let canDelete = true;

      for (const s of this.$store.state.sessions) {
        if (s.locationId === id) {
          canDelete = false;
          break;
        }
      }

      if (canDelete) {
        this.deleteDialogId = id;
        this.$refs.deleteDialog.open()
      } else {
        this.$refs.cannotDeleteDialog.open();
      }
    },
    async doDelete() {
      await this.$store.deleteLocation(this.deleteDialogId);
      this.$store.getLocations();
      this.$uiToast.add("gelöscht");
    },
    getAddress(id) {
      return this.$store.state.addresses.find(a => a.id === id) || {}
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <ui-toolbar :actions="toolbar"></ui-toolbar>
    <ui-cards>
      <ui-card v-for="(session, index) in $store.state.sessions" :key="index">
        <template v-slot:head>{{session.name}}</template>
        {{session.description}}
        <template v-slot:foot>
          <div class="flex flex-row">
            <a class="cursor-pointer mr-3" @click="() => $refs.editSession.open(session.id)"><i class="fal fa-edit"></i></a>
            <a class="cursor-pointer" @click="() => openDeleteDialog(session.id)"><i class="fal fa-trash"></i></a>
          </div>
        </template>
      </ui-card>
    </ui-cards>
  </div>
  <ui-dialog ref="deleteDialog" :is-dangerous="true" confirm-label="Löschen" @confirm="doDelete">
    <div class="text-center pb-2">
      Wollen Sie diese Session wirklich löschen?<br><br>
      Dieser Vorgang kann nicht rückgängig gemacht werden.
    </div>
  </ui-dialog>
  <new-session ref="newSession" />
  <edit-session ref="editSession" />
</template>

<script>
import NewSession from "@/components/Admin/dialogs/NewSession";
import EditSession from "@/components/Admin/dialogs/EditSession";

export default {
  name: "SessionsView",
  components: {
    NewSession,
    EditSession
  },
  methods: {
    openDeleteDialog(id) {
      this.deleteDialogId = id;
      this.$refs.deleteDialog.open()
    },
    async doDelete() {
      await this.$store.deleteSession(this.deleteDialogId);
      this.$store.getSessions();
      this.$uiToast.add("gelöscht");
    }
  },
  data(){
    return {
      deleteDialogId: -1,
      event: {},
      toolbar: [
        {
          title: 'Neue Session',
          label: `<i class="fa fa-plus"></i>`,
          component: 'button',
          type: 'success',
          action: () => this.$refs.newSession.open()
        }
      ],
      options: [
        {
          value: 'value',
          label: 'label'
        }
      ]
    }
  }, created() {
    this.$store.getSessions()
  }
}
</script>

<style scoped>

</style>

<template>
  <ui-dialog :title="$store.state.location.name + ' bearbeiten'" ref="dialog" @confirm="confirm()" @close="dialogClose" size="wide" confirmLabel="Speichern" :disabled-confirm="isSavingNewLocation">
    <ui-field label="Titel der Location">
      <ui-form-input v-model="$store.state.location.name"/>
    </ui-field>
    <ui-field label="Adresse" :content-classes="['flex', 'flex-row', 'w-full']">
      <ui-form-dropdown :options="addressOptions" v-model="$store.state.location.addressId" :disabled="isSavingNewLocation" class="w-full mr-2 flex-grow-0" />
      <ui-button type="blank" size="xs" @click="$refs.newAddrDialog.open()" class="w-24" :disabled="isSavingNewLocation"><i :class="newAddressButtonIconClasses"></i> Neu</ui-button>
    </ui-field>
    <ui-field label="Notizen">
      <ui-form-input type="multiline" v-model="$store.state.location.description"/>
    </ui-field>
  </ui-dialog>
  <ui-dialog title="Neue Adresse" ref="newAddrDialog" confirmLabel="Erstellen" size="wide" @confirm="confirmNewAddress">
    <ui-field label="Straße und Nr.">
      <ui-form-input v-model="address.address"/>
    </ui-field>
    <ui-field label="PLZ">
      <ui-form-input v-model="address.zip"/>
    </ui-field>
    <ui-field label="Ort">
      <ui-form-input v-model="address.city"/>
    </ui-field>
    <ui-field label="Land">
      <ui-form-dropdown :options="countryOptions" v-model="address.country"/>
    </ui-field>
  </ui-dialog>
</template>

<script>
export default {
  name: "EditLocation",
  data(){
    return {
      address:{
      },
      isSavingNewLocation: false,
      countryOptions: [
        {
          label: 'Deutschland',
          value: 'Germany'
        }
      ]
    }
  },
  computed: {
    addressOptions() {
      return this.$store.state.addresses.map(a => {
        return {
          value: a.id,
          label: a.address
        }
      })
    },
    newAddressButtonIconClasses() {
      if (this.isSavingNewLocation) {
        return ['fa', 'fa-spinner-third', 'animate-spin', 'w-4'];
      }

      return ['fal', 'fa-plus', 'w-4'];
    }
  },
  methods: {
    dialogClose() {
      this.$store.state.location.name = '';
      this.$store.state.location.addressId = null;
      this.$store.state.location.description = '';

      this.address.address = '';
      this.address.zip = '';
      this.address.city = '';

      this.address.country = '';
    },
    async confirmNewAddress() {
      this.isSavingNewLocation = true;

      try {
        const newAddress = await this.$store.createAddress(this.address)

        if(newAddress !== null) {
          await this.$store.getAddresses();
          this.$store.state.location.addressId = newAddress;
        }
      } catch (e) {
        console.error(e);
      }

      this.isSavingNewLocation = false;
    },
    async confirm(){
      await this.$store.updateLocation(this.$store.state.location)
    },
    async open(id){
      await this.$store.getLocation(id).then(this.$refs.dialog.open())
    }
  }
}
</script>

<style scoped>

</style>

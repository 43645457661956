<template>
  <ui-dialog title="Neue Location" ref="dialog" @confirm="confirm" @close="dialogClose" size="wide" confirmLabel="Erstellen" :disabled-confirm="isSavingNewLocation">
    <ui-field label="Name der Location">
      <ui-form-input v-model="location.name"/>
    </ui-field>
    <ui-field label="Adresse" :content-classes="['flex', 'flex-row', 'w-full']">
      <ui-form-dropdown :options="addressOptions" v-model="location.addressId" class="w-full mr-2 flex-grow-0" :disabled="isSavingNewLocation" />
      <ui-button type="blank" size="xs" @click="$refs.newAddrDialog.open()" class="w-24" :disabled="isSavingNewLocation"><i :class="newAddressButtonIconClasses"></i> Neu</ui-button>
    </ui-field>
    <ui-field label="Notizen">
      <ui-form-input type="multiline" v-model="location.description"/>
    </ui-field>
  </ui-dialog>
  <ui-dialog title="Neue Adresse" ref="newAddrDialog" confirmLabel="Erstellen" size="wide" @confirm="confirmNewAddress">
    <ui-field label="Straße und Nr.">
      <ui-form-input v-model="address.address"/>
    </ui-field>
    <ui-field label="PLZ">
      <ui-form-input v-model="address.zip"/>
    </ui-field>
    <ui-field label="Ort">
      <ui-form-input v-model="address.city"/>
    </ui-field>
    <ui-field label="Land">
      <ui-form-dropdown :options="countryOptions" v-model="address.country"/>
    </ui-field>
  </ui-dialog>
</template>

<script>
import {location} from "@/store/controllers/location";
import {address} from "@/store/controllers/address";

export default {
  name: "NewLocation",
  data(){
    return {
      isSavingNewLocation: false,
      location: {
      },
      address:{
      },
      setAddress: undefined,
      countryOptions: [
        {
          label: 'Deutschland',
          value: 'Germany'
        }
      ]
    }
  },
  mounted(){
    this.$store.getAddresses()
    this.location = Object.assign(this.location, location)
    this.address = Object.assign(this.address, address)
  },
  computed: {
    addressOptions(){
      return this.$store.state.addresses.map(a => {
        return {
          value: a.id,
          label: a.address
        }
      });
    },
    newAddressButtonIconClasses() {
      if (this.isSavingNewLocation) {
        return ['fa', 'fa-spinner-third', 'animate-spin', 'w-4'];
      }

      return ['fal', 'fa-plus', 'w-4'];
    }
  },
  methods: {
    dialogClose() {
      this.location.name = '';
      this.location.addressId = null;
      this.location.description = '';

      this.address.address = '';
      this.address.zip = '';
      this.address.city = '';

      this.address.country = '';
    },
    async confirmNewAddress() {
      this.isSavingNewLocation = true;

      try {
        const newAddress = await this.$store.createAddress(this.address)

        if(newAddress !== null) {
          await this.$store.getAddresses();
          this.location.addressId = newAddress;
        }
      } catch (e) {
        console.error(e);
      }

      this.isSavingNewLocation = false;
    },
    async confirm() {
      this.location.status = 'ACTIVE';

      await this.$store.createLocation(this.location)
    },
    open(){
      this.setAddress = null;
      this.$refs.dialog.open()
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <ui-dialog :title="$store.state.session.name + ' bearbeiten'" ref="dialog" @confirm="confirm()" @close="dialogClose" size="wide" confirmLabel="Speichern">
    <ui-field label="Name der Session">
      <ui-form-input v-model="$store.state.session.name"/>
    </ui-field>
    <ui-field label="Location">
      <ui-form-dropdown :options="sessionOptions" v-model="$store.state.session.locationId" v-if="sessionOptions.length > 0"/>
    </ui-field>
    <ui-field label="Notizen">
      <ui-form-input type="multiline" v-model="$store.state.session.description"/>
    </ui-field>
    <ui-field label="Datum">
      <ui-form-datepicker v-model="$store.state.session.date" />
    </ui-field>
    <ui-field label="Uhrzeit">
      <ui-form-dropdown :options="timeslotOptions" v-model="$store.state.session.timeSlotId"/>
    </ui-field>
    <ui-field label="Start" v-if="$store.state.session.timeSlotId === 'new'">
      <ui-form-timepicker v-model="time.start"/>
    </ui-field>
    <ui-field label="Ende" v-if="$store.state.session.timeSlotId === 'new'">
      <ui-form-timepicker v-model="time.end"/>
    </ui-field>
  </ui-dialog>
</template>

<script>
export default {
  name: "EditSession",
  data(){
    return {
      time: {
        start: '',
        end: ''
      }
    }
  },
  computed: {
    sessionOptions(){
      return this.$store.state.locations.map(a => {
        return {
          value: a.id,
          label: a.name
        }
      })
    },
    timeslotOptions(){
      let opt = this.$store.state.timeslots.map(a => {
        return {
          value: a.id,
          label: this.getNiceTimeRange(a)
        }
      })

      opt.unshift({
        label: 'Neue Uhrzeit',
        value: 'new'
      })
      return opt
    }
  },
  methods: {
    dialogClose() {
      this.$store.state.session.name = '';
      this.$store.state.session.locationId = null;
      this.$store.state.session.description = '';
      this.$store.state.session.date = '';
      this.$store.state.session.timeSlotId = null;

      this.time.start = '';
      this.time.end = '';
    },
    getNiceTimeRange(slot) {
      const s = new Date(slot.start);
      const e = new Date(slot.end);

      return `${`${s.getHours()}`.padStart(2, '0')}:${`${s.getMinutes()}`.padStart(2, '0')} - ${`${e.getHours()}`.padStart(2, '0')}:${`${e.getMinutes()}`.padStart(2, '0')}`
    },
    async confirm(){
      if(this.$store.state.session.timeSlotId === 'new') {
        const newTimeslotId = await this.$store.createTimeslot({
          start: this.$store.state.session.date + ' ' + this.time.start,
          end: this.$store.state.session.date + ' ' + this.time.end,
        })

        if(newTimeslotId) {
          this.$store.state.session.timeSlotId = newTimeslotId;
        }
      }

      await this.$store.updateSession(this.$store.state.session)
    },
    async open(id){
      await this.$store.getSession(id).then(this.$refs.dialog.open())
    }
  }
}
</script>

<style scoped>

</style>

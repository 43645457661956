<template>
  <div>
    <ui-toolbar :actions="toolbar"></ui-toolbar>
    <ui-field label="Titel">
      <ui-form-input v-model="event.name"/>
    </ui-field>
    <ui-field label="Filter-Tag">
      <ui-form-input v-model="event.slug" :readonly="typeof $store.state.event.mwOptInSetupId === 'number'"/>
    </ui-field>
    <ui-field label="Beginn">
      <ui-form-datepicker v-model="event.startDate" />
    </ui-field>
    <ui-field label="Ende">
      <ui-form-datepicker v-model="event.endDate"/>
    </ui-field>
    <ui-field label="Abonnentenlisten-ID">
      <ui-form-input v-model="event.mwSubscriberListId" :readonly="typeof $store.state.event.mwOptInSetupId === 'number'"/>
    </ui-field>
    <ui-field label="Notizen (intern)">
      <ui-form-input type="multiline" v-model="event.internalNotes"/>
    </ui-field>
    <hr class="my-4">
    <ui-buttons>
      <ui-button @click="saveEvent" type="success" :disabled="isSaving"><i :class="saveButtonIconClasses"></i>Speichern</ui-button>
      <ui-button @click="$refs.eventArchive.open()" type="blank"><i class="far fa-archive"></i>Archivieren</ui-button>
      <ui-button @click="$refs.eventDelete.open()" type="blank"><i class="far fa-trash"></i>Löschen</ui-button>
    </ui-buttons>
    <ui-dialog ref="eventArchive" @confirm="archiveEvent()">Wollen Sie dieses Event wirklich archivieren?</ui-dialog>
    <ui-dialog ref="eventDelete" @confirm="deleteEvent()">Wollen Sie dieses Event wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.</ui-dialog>
  </div>
</template>

<script>
export default {
  name: "SettingsView",
  data(){
    return {
      isSaving: false,
      event: {},
      toolbar: [],
      options: [
        {
          value: '23',
          label: 'label'
        }
      ]
    }
  }, created() {
    this.event = this.$store.state.event
  },
  computed: {
    saveButtonIconClasses() {
      if (this.isSaving) {
        return ['fa', 'fa-spinner-third', 'animate-spin', 'w-6'];
      }

      return ['fa', 'fa-save', 'w-6'];
    }
  },
  methods: {
    async saveEvent() {
      this.isSaving = true;

      try {
        await this.$store.updateEvent(this.event);

        this.$store.clearRecipients();

        await this.$store.getRecipients()
        await this.$store.getTickets()

        this.$uiToast.add("gespeichert");
      } catch (e) {
        console.error(e);
      }

      this.isSaving = false;
    },
    async deleteEvent(){
      await this.$store.deleteEvent(this.$store.state.event.id)
      this.$store.clearEvent()
      this.$store.clearEvents()
      this.$router.push({ name: 'AdminEvents' })
    },
    async archiveEvent(){
      this.$store.state.event.status = 'ARCHIVE'
      await this.$store.updateEvent(this.$store.state.event)
      this.$store.clearEvent()
      this.$store.clearEvents()
      this.$router.push({ name: 'AdminEvents' })
    }
  }
}
</script>

<style scoped>
button > i {
  margin-right: 6px;
}
</style>
